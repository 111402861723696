import React from "react"
import styled from "@emotion/styled"
import { onClientEntry } from "./../../gatsby-browser"

export default function video() {
  if (typeof window !== `undefined`) {
    onClientEntry()
  }

  return (
    <div style={{ width: "100%", maxWidth: "900px" }}>
      {/* <Video style={{ width: '100%'}} src="https://www.youtube.com/embed/gqdc4jLjGKQ" /> */}
      <div
        className="wistia_responsive_padding"
        style={{ padding: "56.25% 0 0 0", position: "relative" }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: "100%",
            left: "0",
            position: "absolute",
            top: "0",
            width: "100%",
          }}
        >
          <div
            className="wistia_embed wistia_async_b7p9ic88gd videoFoam=true"
            style={{ height: "100%", position: "relative", width: "100%" }}
          >
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  )
}
