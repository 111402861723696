import React from "react"
import { Global, css } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import { theme } from "../Styles/theme"
import LandingLayout from "../Components/landingLayout"
import WistiaVideo from "../Components/wistiaVideo"
import { HeadingSecondary } from "../Styles/headers"
import { SectionHero } from "../Styles/text"
import { Section } from "../Styles/section"
import { GlobalStyles } from "../Styles/global"

const FreeCourse = css`
  padding: 1.5rem 2rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: ${theme.pm.pm400};
`

const BtnLarge = css`
  background: #2d80fa;
  padding: ${theme.pm.pm200} ${theme.pm.pm300};
  color: #fff;
  display: inline-block;
  border: none;
  font-size: ${theme.text.text400};
  box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.15);
`

export default () => (
  <ThemeProvider theme={theme}>
    <Global styles={GlobalStyles} />
    <LandingLayout>
      <Section css={FreeCourse} backgroundColor="secondary">
        <HeadingSecondary style={{ marginBottom: "2rem"}}>
          Freelancing for Developers
        </HeadingSecondary>
        <WistiaVideo />
        <a
          style={{ marginTop: "2rem", padding: "1.4rem 5rem"}}
          css={BtnLarge}
          href="http://www.freelancingfordevelopers.com/apply"
        >
          LET'S TALK
        </a>
      </Section>
    </LandingLayout>
  </ThemeProvider>
)
